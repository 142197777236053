import React from 'react'
import { useHistory } from 'react-router-dom'
import { string } from 'prop-types'
import flow from 'lodash.flow'

import { useAlert } from 'hooks/useAlert'
import * as UserApi from 'services/api/UserApi'
import * as UtilsApi from 'services/api/UtilsApi'
import uploadFile from 'services/uploadFile'
import { apeCompanyList, apeAssociationList } from 'utils/constants'
import { isEmailValid, isSiretValid, isRnaValid, isValidFrenchPhoneNumber, isNotEmpty } from 'utils/validation'
import { employeeCategory, getCorrespondingEmployeeCategory } from 'utils/domain/beneficiary'
import { scrollToTop } from 'utils/scrollTo'
import { getAccessToken } from 'utils/domain/user'
import { ASSOCIATION_VALUE, beneficiaryList, PRIVATE_SECTOR_VALUE, PUBLIC_SECTOR_VALUE, PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE } from 'utils/domain/beneficiary'
import { publicRoutes } from 'router/routes'

import FormElement from 'components/shared/FormElement'

import PDFCGU from 'assets/data/CGU-V20240802.pdf'
import useBonus from 'hooks/useBonus'

export const getKeywords = (data) => {
    let keywords = ''
    keywords += 'O-S-' + data?.structureSiret + ' '
    keywords += 'O-CP-' + data?.structurePostCode + ' '
    keywords += 'O-APE-' + data?.structureCodeApe + ' '
    keywords += 'O-G-' + data?.role + ' '
    keywords += 'O-FN-' + data?.contactFirstname + ' '
    keywords += 'O-LN-' + data?.contactLastname + ' '
    keywords += data?.contactEmail + ' '
    keywords += data?.structureName + ' '
    keywords += data?.structureAddress + ' '
    keywords += data?.structurePostCode + ' '
    keywords += data?.structureCity + ' '
    keywords += data?.contactFirstname + ' '
    keywords += data?.contactLastname + ' '
    return keywords
}

const defaultFormFields = {
    // Structure
    role: PRIVATE_SECTOR_VALUE,
    structureName: '',
    structureSiret: '',
    structureRna: 'W',
    structureCodeApe: '',
    structureEmployeeQuantity: '',
    structureEmployeeCategory: null,
    structureAddress: '',
    structurePostCode: '',
    structureCity: '',
    structurePhonenumber: '',
    // Contact
    contactLastname: '',
    contactFirstname: '',
    contactRole: '',
    connexionEmail: '',
    contactEmail: '',
    contactPhonenumber: '',
    contactCertification: false,
    // Platform
    document: '',
    code: '',
    password: '',
    passwordConfirmation: '',
    cgu: false,
    active_entity: true
}

const defaultFormEvents = {
    ready: true
}

const FormSignup = ({ className }) => {
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const history = useHistory()
    const { addAlert } = useAlert()
    const { calculateBonus } = useBonus()

    const disambiguateRole = React.useCallback(
        (role) => {
            switch (parseInt(role)) {
                case PRIVATE_SECTOR_VALUE:
                    setCurrentRole('company')
                    break
                case PUBLIC_SECTOR_VALUE:
                    setCurrentRole('public')
                    break
                case ASSOCIATION_VALUE:
                    setCurrentRole('asso')
                    break
                case PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE:
                    setCurrentRole('company-public-vocation')
                    break
                default:
                    break
            }
        },
        []
    )
    const [currentRole, setCurrentRole] = React.useState(null)
    React.useEffect(() => disambiguateRole(formFields?.role), [disambiguateRole, formFields?.role])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleConnexionEmailChange = (event) => {
        setFormFields({
            ...formFields,
            contactEmail: event.target.value,
            connexionEmail: event.target.value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const getOnlyBeneficiaryStateProperties = (state) => {
        return {
            ...flow([
                Object.entries,
                // only keep state properties starting with (structure|asso|contact)
                arr => arr.filter(([key]) => key.match(new RegExp(/^(structure|asso|contact).*$/))),
                Object.fromEntries
            ])(state)
        }
    }

    const getApiObject = () => ({
        entity_id: backendUserSettings?.entity_id || null,
        entity_data: {
            ...getOnlyBeneficiaryStateProperties(formFields),
            siret: formFields?.structureSiret,
            mail: formFields?.connexionEmail,
            contactEmail: formFields?.contactEmail,
            gender: parseInt(formFields?.role),
            active_entity: formFields.active_entity,
            structureEmployeeCategory: formFields.structureEmployeeCategory
        },
        code: formFields?.code,
        password: formFields?.password,
        keywords: getKeywords(formFields)
    })

    // backendUserSettings (entity_id, media_url_prefix) are sent by the backend when the mail si validated
    const [backendUserSettings, setBackendUserSettings] = React.useState(null)
    const handleSubmit = async (event) => {
        event.preventDefault()

        if(!isNotEmpty(backendUserSettings)) return addAlert('error', 'Veuillez valider votre email.')

        const formReady = (errors.length === 0)
        if (!formReady) {
            setShowLocalErrors(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return
        }

        // hide error
        setShowLocalErrors(false)
        // prevent form to be submitted again
        setFormEvents({ ...formEvents, ready: false })

        try {
            // prepare data
            const apiObj = getApiObject()

            // Calculate coordinates
            const coordinates = await UtilsApi.getCoordinatesFromAdress(formFields?.structureAddress, formFields?.structurePostCode)
            if (!isNotEmpty(coordinates)) throw new Error('Adresse non reconnue, code postal inexistant')

            apiObj.entity_data['latitude'] = coordinates[1]
            apiObj.entity_data['longitude'] = coordinates[0]

            // Add documents if there is doc
            if (isNotEmpty(formFields?.document)) {
                apiObj.entity_data['documents'] = [{
                    media_gender: 'KBIS',
                    media_id: formFields?.document?.id,
                    media_url: `${backendUserSettings?.media_url_prefix}${formFields?.document?.id}.${formFields?.document?.extension}`,
                    media_hash: formFields?.document?.value
                }]
            }

            // Calculate prime
            if (isNotEmpty(formFields?.structurePostCode) && isNotEmpty(formFields?.structureEmployeeCategory)) {
                apiObj.entity_data['prime'] = calculateBonus(formFields?.structurePostCode, formFields?.structureEmployeeCategory)
            }

            // Manage API
            const { getError: getSignUpError } = await UserApi.signUp(apiObj)
            if(getSignUpError()) throw getSignUpError()

            if (isNotEmpty(formFields?.document)) {
                await uploadFile('Kbis', {
                    id: formFields?.document?.id,
                    extension: formFields?.document?.extension,
                    blob: formFields?.document?.blob
                }).catch((error) => {
                    console.error(error?.toString())
                    console.info({ blob: formFields?.document?.blob }, { token: getAccessToken() })
                    addAlert('error', 'Une erreur est survenu pendant l\'envoi du document, veuillez vous connecter puis le mettre à jour sur votre page "Mon compte"')
                })
            }

            addAlert('success', 'Inscription validée.')
            // Redirect to login view
            history.push(publicRoutes.login.path)
        } catch (error) {
            addAlert('error', error?.toString())
            setShowGlobalError(true)
        } finally {
            setFormEvents({ ...formEvents, ready: true })
        }
    }

    React.useEffect(() => {
        if(isNotEmpty(formFields?.structureEmployeeQuantity)) {
            const correspondingCategory = getCorrespondingEmployeeCategory(formFields?.structureEmployeeQuantity)
            setFormFields((s) => ({
                ...s,
                structureEmployeeCategory: correspondingCategory
            }))
        }
    }, [formFields?.structureEmployeeQuantity])

    return (
        <form className={className ? className : ''}>
            {showGlobalError &&
                <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>
            }

            {/* Structure */}
            <div className="u-pd-top-m u-pd-top-xl@main u-mg-bottom-l">
                <div className="l-container-lg">
                    <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Nature de l'établissement</h2>
                    <p className="u-mg-bottom-m">Si votre entreprise a plusieurs sites, vous devez créer un compte par site. Les informations ci-dessous concernent uniquement cet établissement. </p>
                    <div className={'l-grid u-mg-negative-hz-m'}>
                        <div className="l-col-12 u-pd-m">
                            {/* Choose role */}
                            <FormElement
                                value={parseInt(formFields.role)}
                                name="role"
                                type="radio"
                                label="Je souhaite être référencé en tant que :"
                                options={beneficiaryList}
                                // options={entityGenderList}
                                className="u-mg-bottom-l"
                                classNameOptionsContainer="u-flex-dir-col"
                                classNameLabel="u-mg-bottom-m"
                                classNameOption="u-flex-center-vt"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Raison sociale */}
                            <FormElement
                                value={formFields.structureName}
                                name="structureName"
                                type="text"
                                label="Nom de l'établissement"
                                className="u-mg-bottom-m"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main">
                            {/* SIRET */}
                            <FormElement
                                value={formFields.structureSiret}
                                name="structureSiret"
                                type="text"
                                label="SIRET du site"
                                className="u-pd-m u-mg-bottom-m"
                                maxLength={14}
                                required={true}
                                customRules={[
                                    {
                                        key: 'siret-invalid',
                                        check: (value) => isSiretValid(value),
                                        message: "Le numéro SIRET n'est pas valide."
                                    }
                                ]}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                            { currentRole === 'public' &&
                                <p className="u-fs-xs u-mg-bottom-m">Si vous avez plusieurs numéros de SIRET pour votre établissement, indiquez en un correspondant à l'adresse renseignée ci-après.</p>
                            }
                        </div>

                        {currentRole !== 'public' &&
                            <>
                                <div className={ 'l-col-12 l-col-6@main u-pd-m'}>
                                    {/* Document */}
                                    <FormElement
                                        value={formFields.document}
                                        type="file"
                                        name="document"
                                        label={(() => {
                                            if (currentRole === 'asso') return 'Annonce de votre existence publiée au JOAFE'
                                            if (currentRole === 'company-public-vocation') return 'Avis de situation au répertoire SIRENE'
                                            return 'Kbis'
                                        })()}
                                        maxFileSize={10}
                                        acceptPDF={true}
                                        className={'u-mg-bottom-m'}
                                        required={currentRole !== 'asso'} // KBIS and SIRENE are mandatory
                                        showErrors={showLocalErrors}
                                        onValidate={handleValidation}
                                        onChange={handleInputChange}
                                    />
                                    { currentRole === 'company' &&
                                        <p className="u-fs-xxs u-mg-bottom-m">Extrait Kbis de moins 3 mois au format png, jpg ou pdf</p>
                                    }
                                    { currentRole === 'asso' &&
                                        <p className="u-fs-xxs u-mg-bottom-m">Un document de 10Mo maximum au format pdf, png ou jpg</p>
                                    }
                                </div>
                                <div className="l-col-12 l-col-6@main u-pd-m">
                                    {/* Code APE */}
                                    <FormElement
                                        value={formFields.structureCodeApe}
                                        options={currentRole === 'company' ? apeCompanyList : apeAssociationList}
                                        name="structureCodeApe"
                                        type="select"
                                        label="Code APE / NAF"
                                        className="u-mg-bottom-m"
                                        required={false}
                                        showErrors={showLocalErrors}
                                        onValidate={handleValidation}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </>
                        }

                        {/* {currentRole === "assoHAM" &&  */}
                        {currentRole === 'asso' &&
                            <div className="l-col-12">
                                <div className="l-grid">
                                    <div className="l-col-6 u-pd-m">
                                        {/* RNA */}
                                        <FormElement
                                            value={formFields.structureRna}
                                            name="structureRna"
                                            type="text"
                                            label="RNA"
                                            className="u-mg-bottom-m"
                                            maxLength={9}
                                            required={false}
                                            customRules={[
                                                {
                                                    key: 'rna-invalid',
                                                    check: (value) => {
                                                        return (value && (value.length > 1) && isRnaValid(value)) || true
                                                    },
                                                    message: "Le numéro RNA n'est pas valide."
                                                }
                                            ]}
                                            showErrors={showLocalErrors}
                                            onValidate={handleValidation}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="l-col-12 u-pd-m">
                            {/* Adress */}
                            <FormElement
                                value={formFields.structureAddress}
                                name="structureAddress"
                                type="text"
                                label="Adresse"
                                className="u-mg-bottom-m"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-6 l-col-4@main u-pd-m">
                            {/* structurePostCode */}
                            <FormElement
                                value={formFields.structurePostCode}
                                name="structurePostCode"
                                type="number"
                                label="Code postal"
                                className="u-mg-bottom-m"
                                maxLength={5}
                                required={true}
                                customRules={[
                                    {
                                        key: 'structurePostCode-invalid',
                                        check: (value) => {
                                            return value ? (value.length === 5) : true
                                        },
                                        message: "Le code postal n'est pas valide."
                                    }
                                ]}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-8@main u-pd-m">
                            {/* City */}
                            <FormElement
                                value={formFields.structureCity}
                                name="structureCity"
                                type="text"
                                label="Ville"
                                className="u-mg-bottom-m"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Tel */}
                            <FormElement
                                value={formFields.structurePhonenumber}
                                name="structurePhonenumber"
                                type="text"
                                label="Numéro de téléphone de l'établissement"
                                className="u-mg-bottom-m"
                                required={true}
                                customRules={[
                                    {
                                        key: 'structure-phone-invalid',
                                        check: (value) => isValidFrenchPhoneNumber(value),
                                        message: "Le format de numéro de téléphone n'est pas valide."
                                    }
                                ]}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="l-grid u-mg-negative-hz-m">
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Quantité */}
                            <FormElement
                                value={formFields.structureEmployeeQuantity}
                                name="structureEmployeeQuantity"
                                type="number"
                                label="Nombre exact de salariés dans l'établissement"
                                className="u-mg-bottom-m"
                                required={false}
                                customRules={[
                                    {
                                        key: 'employee-quantity-invalid',
                                        check: (value) => {
                                            return value ? (value > 0) : true
                                        },
                                        message: 'Le nombre de salariés doit être positif non nul.'
                                    }
                                ]}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Catégorie de salariés */}
                            <FormElement
                                value={formFields.structureEmployeeCategory}
                                options={employeeCategory}
                                name="structureEmployeeCategory"
                                type="select"
                                label="Effectif de l'établissement"
                                className="u-mg-bottom-m"
                                required={true}
                                localValidation={
                                    // Validate that structureEmployeeCategory corresponds to the structureEmployeeQuantity
                                    formFields.structureEmployeeQuantity
                                    ? getCorrespondingEmployeeCategory(formFields?.structureEmployeeQuantity)?.value === formFields.structureEmployeeCategory
                                    : true
                                }
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                            <p className="u-fs-xxs u-mg-bottom-m">
                                Attention, il vous est indiqué de préciser le nombre d’employés qui travaillent effectivement à l’adresse de votre site et non celui de votre groupe ou de votre société. Ce champ fera l’objet d’une vérification de la part du programme OEPV.
                            </p>
                        </div>
                    </div>
                    <p className="">Si vous souhaitez profiter du programme pour plusieurs de vos établissements, vous devez inscrire chaque établissement indépendamment et indiquer respectivement leur nombre de salariés.</p>
                </div>
            </div>

            {/* Contact */}
            <div className="u-bg-secondary u-pd-hz-m u-pd-vt-m u-pd-vt-xl@main u-mg-bottom-l">
                <div className="l-container-lg">
                    <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Responsable du compte</h2>
                    <div className="l-grid u-mg-negative-hz-m">
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Lastname */}
                            <FormElement
                                value={formFields.contactLastname}
                                name="contactLastname"
                                type="text"
                                label="Nom"
                                className="u-mg-bottom-m"
                                classNameInput="u-bg-transparent"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Firstname */}
                            <FormElement
                                value={formFields.contactFirstname}
                                name="contactFirstname"
                                type="text"
                                label="Prénom"
                                className="u-mg-bottom-m"
                                classNameInput="u-bg-transparent"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Contact role */}
                            <FormElement
                                value={formFields.contactRole}
                                name="contactRole"
                                type="text"
                                label="Fonction"
                                className="u-mg-bottom-m"
                                classNameInput="u-bg-transparent"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Phone */}
                            <FormElement
                                value={formFields.contactPhonenumber}
                                name="contactPhonenumber"
                                type="text"
                                label="Téléphone"
                                className="u-mg-bottom-m"
                                classNameInput="u-bg-transparent"
                                required={true}
                                customRules={[
                                    {
                                        key: 'phone-invalid',
                                        check: (value) => isValidFrenchPhoneNumber(value),
                                        message: "Le format de numéro de téléphone n'est pas valide."
                                    }
                                ]}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Email de connexion*/}
                            <FormElement
                                value={formFields.connexionEmail}
                                contactEmailValue={formFields.contactEmail}
                                name="connexionEmail"
                                type="email"
                                label="E-mail de connexion"
                                className="u-mg-bottom-s"
                                classNameInput="u-bg-transparent"
                                mailToValidate={true}
                                apiCallback={setBackendUserSettings}
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleConnexionEmailChange}
                            />
                            <p className="u-fs-xs u-mg-bottom-m">Vous recevrez un code par email, nécessaire pour continuer.</p>
                            <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email correspond à votre identifiant pour vous connecter à la plateforme, c’est aussi celui qui sera utilisé si vous souhaitez modifier votre mot de passe de connexion. Une fois renseigné, il ne pourra plus être modifié tout au long du programme.</p>
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Email verification code */}
                            <FormElement
                                value={formFields.code}
                                name="code"
                                type="number"
                                label="Code de confirmation"
                                className="u-mg-bottom-s"
                                classNameInput="u-bg-transparent"
                                maxLength={6}
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                            <p className="u-fs-xxs u-mg-bottom-m">Saisissez ici le code à 6 chiffres reçu par e-mail.</p>
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Email de contact*/}
                            <FormElement
                                value={formFields.contactEmail}
                                name="contactEmail"
                                type="email"
                                label="E-mail de contact"
                                className="u-mg-bottom-s"
                                classNameInput="u-bg-transparent"
                                apiCallback={setBackendUserSettings}
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                            <p className="u-fs-xxs u-justify u-mg-bottom-m">Cet email désigne l’adresse sur laquelle vous seront envoyés tous les emails de la plateforme (confirmations de demandes, informations sur votre parcours, validations de documents, récapitulatifs de commandes de prestations, bordereaux de versement de prime). C’est aussi l’email auquel auront accès tous les prestataires du programme pour vous contacter.</p>
                        </div>
                        <div className="l-col-12 u-pd-m">
                            {/* Certification */}
                            <FormElement
                                value={formFields.contactCertification}
                                name="contactCertification"
                                type="checkbox"
                                label=""
                                checkboxLabel={
                                    <span className="">
                                        Je reconnais avoir la délégation pour inscrire mon établissement à OEPV et de pouvoir prendre des décisions relatives à la politique vélo de l'établissement.
                                    </span>
                                }
                                className={'u-pd-vt-m u-primary u-bold'}
                                classNameInput=""
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Account */}
            <div className="u-pd-vt-m u-pd-vt-xl@main u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main">
                <div className="l-container-lg">
                    <h2 className="c-h2 u-bold u-primary u-mg-bottom-l">Votre compte OEPV</h2>
                    <p className="u-mg-bottom-l">Votre identifiant sera votre adresse e-mail : <span className="u-bold">{isEmailValid(formFields?.contactEmail) ? formFields?.contactEmail : ''}</span></p>
                    <div className="l-grid u-mg-negative-hz-m">
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Password */}
                            <FormElement
                                value={formFields.password}
                                name="password"
                                type="password"
                                passwordCreate={true}
                                label="Mot de passe"
                                className="u-mg-bottom-s"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                            <p className="u-fs-xs u-mg-bottom-m">(8 caractères minimum, au moins 1 chiffre, au moins 1 minuscule, au moins 1 majuscule )</p>
                        </div>
                        <div className="l-col-12 l-col-6@main u-pd-m">
                            {/* Password confirmation*/}
                            <FormElement
                                value={formFields.passwordConfirmation}
                                name="passwordConfirmation"
                                type="password"
                                passwordCreate={true}
                                passwordToConfirm={formFields.password}
                                label="Confirmer le mot de passe"
                                className="u-mg-bottom-m"
                                classNameInput="u-bg-transparent"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="l-col-12 u-pd-m">
                            {/* CGU */}
                            <FormElement
                                value={formFields.cgu}
                                name="cgu"
                                type="checkbox"
                                label=""
                                checkboxLabel={
                                    <span className="u-grey90 u-fs-xxs">
                                        Je reconnais avoir pris connaissance des présentes <a href={PDFCGU}target={'_blank'} className="u-underline" rel="noopener noreferrer">Conditions Générales d'Utilisation</a> et les accepter sans réserve.
                                    </span>
                                }
                                className={'u-pd-vt-m'}
                                classNameInput="c-checkbox--cgu"
                                classNameOption="u-flex-start-vt"
                                required={true}
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="u-flex u-flex-center-hz u-pd-top-l u-pd-bottom-xl u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main">

                    <button
                        type="submit"
                        className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                        style={{ width: '100%', maxWidth: '460px' }}
                        onClick={handleSubmit}
                    >
                        Créer mon compte OEPV
                    </button>

                {!formEvents.ready &&
                    <div className="c-spinner"></div>
                }
            </div>
        </form>
    )
}

FormSignup.propTypes = {
    className: string
}

export default FormSignup
