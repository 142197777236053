import React from 'react'
import { useHistory } from 'react-router-dom'
import * as UserApi from 'services/api/UserApi'
import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import { getKeywords } from 'components/auth/FormSignup'
import PDFCGU from 'assets/data/CGU-V20240802.pdf'
import { privateRoutes } from 'router/routes'

import ProtectedLayout from 'layouts/Protected'
import FormElement from 'components/shared/FormElement'
import { scrollToTop } from 'utils/scrollTo'

const defaultFormEvents = {
    ready: true
}

const defaultFormFields = {
    acceptedCGU: false
}

const AcceptCGU = () => {
    const { user, refreshUser, isBackOfficeSignIn } = useAuth()
    const { addAlert } = useAlert()
    const history = useHistory()

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        // Validate inputs
        const formReady = !(errors.length > 0)

        if (!formReady) {
            setShowLocalErrors(true)
            addAlert('error', 'Veuillez accepter les CGU.')
            scrollToTop()
        }
        else {
            // hide error
            setShowGlobalError(false)
            setShowLocalErrors(false)
            // prevent form to be submitted again
            setFormEvents({
                ...formEvents,
                ready: false
            })

            let updateUserResult

            if(isBackOfficeSignIn) {
                // TODO: Remove usage of the UserApi.updateBackOfficeUser function if CGU are not necessary for back office users
                const { res: backOfficeUserUpdated } = await UserApi.updateBackOfficeUser({
                    mail: user?.entity_data?.mail,
                    description: {
                        acceptedCGU: formFields?.acceptedCGU
                    }
                })
                updateUserResult = backOfficeUserUpdated
            } else {
                const editedUser = {
                    entity_id: user?.entity_id,
                    entity_data: {
                        ...user?.entity_data,
                        acceptedCGU: formFields?.acceptedCGU
                    },
                    status: user?.status,
                    keywords: getKeywords(formFields),
                    last_row_hash: user?.row_hash
                }
                
                const { res } = await UserApi.updateUser(editedUser)
                updateUserResult = res
            }



            if (!updateUserResult || updateUserResult?.error) {
                switch (updateUserResult?.error?.code) {
                    case 'DuplicatesValueException':
                        addAlert('error', 'Le siret saisi appartient à un autre compte inscrit')
                        break
                    case 'EntityNotFoundException':
                        addAlert('error', "Votre compte n'a pas été trouvé")
                        break
                    case 'EntityVersionMismatchException':
                        addAlert('error', "Les informations que vous aviez, avant modification, n'étaient pas à jour. Veuillez actualiser la page pour disposer des informations à jour.")
                        break
                    case 'NotAuthorizedException':
                        addAlert('error', "La modification de votre compte n'est pas autorisée")
                        break
                    default:
                        addAlert('error', "Une erreur s'est produite lors de l'enregistrement")
                }
                setShowGlobalError(true)
                setFormEvents({
                    ...formEvents,
                    ready: true
                })
            }
            else {
                refreshUser()
                history.push(privateRoutes.home.path)
            }

        }
    }


    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container u-relative">

                <h1 className="c-h1 u-primary u-center u-mg-vt-xl">Bienvenue sur votre compte OEPV</h1>

                <div className="l-container-md u-pd-vt-m">
                    <p className="u-center u-pd-right-l@main u-mg-bottom-m u-mg-0@main">
                        Afin de pouvoir accéder à votre compte, merci de valider ses C.G.U.
                    </p>
                </div>
                <form >
                    {showGlobalError &&
                        <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>
                    }
                    <div className="u-flex u-flex-center-hz ">
                        <FormElement
                            value={formFields.acceptedCGU}
                            name="acceptedCGU"
                            type="checkbox"
                            label=""
                            checkboxLabel={
                                <span className="u-grey90 u-fs-xxs">
                                    Je reconnais avoir pris connaissance des présentes <a href={PDFCGU} target={'_blank'} className="u-underline" rel="noopener noreferrer">Conditions Générales d'Utilisation</a> et les accepter sans réserve.
                                </span>
                            }
                            className="l-col-12 l-col-6@main u-pd-m"
                            classNameInput="c-checkbox--cgu"
                            classNameOption="u-flex-start-vt"
                            required={true}
                            showErrors={showLocalErrors}
                            onValidate={handleValidation}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="u-flex u-flex-center-hz u-pd-l" style={{ minWidth: '250px' }}>
                        {formEvents.ready &&
                            <button
                                type="submit"
                                className="c-btn c-btn--primary c-btn--m u-pd-hz-xl u-mg-bottom-xl"
                                style={{ width: '100%', maxWidth: '460px' }}
                                onClick={handleSubmit}
                                disabled={!formFields?.acceptedCGU}
                            >
                                Enregistrer
                            </button>
                        }
                        {!formEvents.ready &&
                            <div className="c-spinner"></div>
                        }
                    </div>
                </form>

            </div>
        </ProtectedLayout>
    )
}

export default AcceptCGU
